<template>
  <HeaderComponent @navigate="handleNavigate" />

  <!-- Contenido dinámico va aquí -->
  <div id="componente">
    <keep-alive>
      <component :is="component" @navigate="handleNavigate"></component>
    </keep-alive>
  </div>

  <footer
    class="d-flex justify-content-center fixed-bottom mx-auto bg-light p-2"
  >
    <div id="buttonSlider" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner d-flex flex-row">
        <!-- Renderizar botones para cada componente cargado -->
        <div
          v-for="comp in loadedComponents"
          :key="comp"
          class="btn-group"
          :class="{ 'active-component': component === comp }"
          style="border: 1px solid #ccc; border-radius: 5px"
        >
          <!-- Parte que muestra el nombre del componente -->
          <button
            class="btn btn-sm boton-outline-violeta flex-grow-1"
            @click="navigateToComponent(comp)"
          >
            {{ comp }}
          </button>
          <!-- Parte para cerrar el componente -->
          <button
            class="btn btn-sm boton-outline-violeta"
            @click="closeComponent(comp)"
          >
            <img src="@/assets/imagenes/botones/cancelar.png" alt="Cerrar" width="20">
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { isUndefined } from 'pdfmake/src/helpers';
import vClickOutside from 'click-outside-vue3';
export default {
  data() {
    return {
      // Iniciar siempre en RRHH para que no de error al cargar directamente las vacaciones o las ausencias
      component: 'RRHH',
      loadedComponents: ['RRHH'], // Iniciamos con el componente inicial
    };
  },

  components: {
    HeaderComponent: defineAsyncComponent(() =>
      import('@/components/HeaderComponent.vue'),
    ),
    /********************************
     * GESTION
     ********************************/
    GESTION: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/inicio/Inicio.vue'),
    ),
    CallCenter: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/callcenter/CallCenter.vue'),
    ),
    Familias: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/catalogo/FamiliasLayout.vue'),
    ),
    Subfamilias: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/catalogo/SubfamiliasLayout.vue'),
    ),
    Referencias: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/catalogo/ReferenciasLayout.vue'),
    ),
    Ayuda: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/ayuda/AyudaLayout.vue'),
    ),
    AyudaFichajes: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/ayuda/fichajes/AyudaFichajes.vue'),
    ),
    Clientes: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/clientes/ClientesLayout.vue'),
    ),
    Pedidos: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/documentos/PedidosLayout.vue'),
    ),
    Presupuestos: defineAsyncComponent(() =>
      import('@/modulos/erp/layouts/documentos/PresupuestosLayout.vue'),
    ),
    
    // ALMACEN
    Almacenes: defineAsyncComponent(() =>
      import('@/modulos/sga/layouts/almacen/AlmacenesLayout.vue'),
    ),

    /********************************
     * TALLER
     ********************************/
    TALLER: defineAsyncComponent(() =>
      import('@/modulos/taller/layouts/CalendarioTaller.vue'),
    ),
    Citas: defineAsyncComponent(() =>
      import('@/modulos/taller/layouts/CitasLayout.vue'),
    ),
    CitasPospuestas: defineAsyncComponent(() =>
      import('@/modulos/taller/layouts/CitasPospuestasLayout.vue'),
    ),
    DocumentosTaller: defineAsyncComponent(() =>
      import('@/modulos/taller/layouts/GestionDocumentalLayout.vue'),
    ),
    CitasHoy: defineAsyncComponent(() =>
      import('@/modulos/taller/layouts/CitaDiaActualLayout.vue'),
    ),


    /********************************
     * CONFIGURACION
     ********************************/
    Usuarios: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/UsuariosLayout.vue'),
    ),
    Grupos: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/GruposLayout.vue'),
    ),
    Empresas: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/EmpresasLayout.vue'),
    ),
    Delegaciones: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/DelegacionesLayout.vue'),
    ),
    Agentes: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/AgentesLayout.vue'),
    ),
    Dependientes: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/DependientesLayout.vue'),
    ),
    GruposComerciales: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/GruposComercialesLayout.vue'),
    ),
    Impuestos: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/ImpuestosLayout.vue'),
    ),
    Festivos: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/FestivosLayout.vue'),
    ),
    ZonasFestivos: defineAsyncComponent(() =>
      import('@/modulos/configuracion/layouts/ZonasFestivosLayout.vue'),
    ),
    
    

    /********************************
     * RRHH
     ********************************/
    RRHH: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/InicioLayout.vue'),
    ),
    Fichajes: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/FichajesLayout.vue'),
    ),
    Documentos: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/DocumentosLayout.vue'),
    ),
    Vacaciones: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/VacacionesLayout.vue'),
    ),
    Ausencias: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/AusenciasLayout.vue'),
    ),
    Eventos: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/EventosLayout.vue'),
    ),
    Turnos: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/TurnosLayout.vue'),
    ),
    Calendario: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/CalendarioLayout.vue'),
    ),
    Noticias: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/NoticiasLayout.vue'),
    ),
    Documentos: defineAsyncComponent(() =>
      import('@/modulos/rrhh/layouts/DocumentosLayout.vue'),
    ),

    /********************************
     * LOGS
     ********************************/
    Logs: defineAsyncComponent(() =>
      import('@/modulos/logs/components/TablaListadoLogsComponent.vue'),
    ),

    /********************************
     * PANTALLAS
     ********************************/
    Pantallas: defineAsyncComponent(() =>
      import('@/modulos/pantallas/Pantallas.vue'),
    ),
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    handleNavigate(value) {
      console.log(`Evento navigate recibido con valor: ${value}`);
      this.component = value;

      // Agregar a la lista de componentes cargados si no está presente
      if (!this.loadedComponents.includes(value)) {
        this.loadedComponents.push(value);
      }
    },

    navigateToComponent(comp) {
      this.component = comp;
    },

    closeComponent(comp) {
      // Eliminar el componente de la lista de componentes cargados
      this.loadedComponents = this.loadedComponents.filter((c) => c !== comp);

      // Si el componente que se cierra es el actual, navega al primero disponible
      if (this.component === comp) {
        this.component = this.loadedComponents.length
          ? this.loadedComponents[0]
          : 'Home'; // Navega a 'Home' si no hay componentes cargados
      }
    },
  },
  mounted() {
    // Si se refresca la página se obliga al login
    if (isUndefined(this.$store.nombreUsuario)) {
      this.$router.push('/');
    }

    /*
    if (this.$store.modulo_erp === 0) {
      this.$router.push('/rrhh');
    }
      */
  },
};
</script>

<style scoped>
.active-component .boton-outline-violeta {
  background-color: #808080;
  color: white;
  border-color: #808080;
}
</style>
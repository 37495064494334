import { createRouter, createWebHashHistory } from 'vue-router';

// ERP
import Erp from '@/modulos/erp/Erp';

// RRHH
import RRHH from '@/RRHH/login.vue';

// PRUEBAS
import GoogleCalendar from '@/modulos/pruebas/GoogleCalendar.vue';

// Web
import Home from '@/web/home/Home.vue';
import RecursosHumanos from '@/web/rrhh/RecursosHumanos';
import Sga from '@/web/sga/Sga';
import Cms from '@/web/cms/Cms';
import LoginClientes from '@/web/login-clientes/LoginClientes';
import CrearCuentaDemo from '@/web/crear-cuenta-demo/CrearCuentaDemo';

// SEO
import updateMetaTags from '@/helpers/seo';

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    meta: {
      'title': '🟣 ERP Cloud para la gestión de su empresa o negocio V-PRO 360 🟣',
      'description': 'ERP en la nube,diseñado para optimizar la gestión integral de empresas.Aprovecha la flexibilidad,escalabilidad y seguridad avanzada que ofrece nuestra solución para mejorar tus procesos de negocio,desde finanzas y operaciones hasta recursos humanos y gestión de la cadena de suministro.¡Empieza a potenciar tu negocio!🟣',
      'keywords': 'ERP, SGA, CMS, software de gestion, erp cloud, RRHH, fichajes, control de fichaje, control de taller, software contable',
      'twitter:title': '🟣 ERP Cloud para la gestión de su empresa o negocio V-PRO 360 🟣',
      'twitter:description': '🟣 ERP Cloud para la gestión de su empresa o negocio V-PRO 360 🟣',
      'og:site_name': 'V-PRO 360',
      'og:title': '🟣 ERP Cloud para la gestión de su empresa o negocio V-PRO 360 🟣',
      'og:description': 'ERP en la nube,diseñado para optimizar la gestión integral de empresas.Aprovecha la flexibilidad,escalabilidad y seguridad avanzada que ofrece nuestra solución para mejorar tus procesos de negocio,desde finanzas y operaciones hasta recursos humanos y gestión de la cadena de suministro.¡Empieza a potenciar tu negocio!🟣',
      'og:url': 'https://www.vpro360.es/',
      'og:image': 'https://www.vpro360.es/img/logos/vpro360.png',
      'og:image:width': '600',
      'og:image:height': '315',
      'canonicalUrl': 'https://www.vpro360.es/',
      'schema.DC': 'https://purl.org/dc/elements/1.1/',
      'schema.DCTERMS': 'https://purl.org/dc/terms/',
      'DC.Description': '🟣 ERP Cloud para la gestión de su empresa o negocio V-PRO 360 🟣',
      'DC.Title': '🟣 ERP Cloud para la gestión de su empresa o negocio V-PRO 360 🟣',
      'DC.Subject': 'ERP, SGA, CMS, software de gestion, erp cloud, RRHH, fichajes, control de fichaje, control de taller, software contable',
      'DC.Identifier': 'https://www.vpro360.es/',
    },
  },
  {
    path: '/recursos-humanos',
    component: RecursosHumanos,
    name: 'recursos-humanos',
    meta: {
      'title': '🟣 RRHH Cloud para la gestión de su empresa o negocio V-PRO 360🟣',
      'description': 'RRHH en la nube,diseñado para optimizar la gestión integral de empresas.Aprovecha la flexibilidad,escalabilidad y seguridad avanzada que ofrece nuestra solución para mejorar tus procesos de negocio,desde finanzas y operaciones hasta recursos humanos y gestión de la cadena de suministro.¡Empieza a potenciar tu negocio!',
      'keywords': 'RRHH, ERP, SGA, CMS, software de gestion, erp cloud, fichajes, control de fichaje, control de taller, software contable',
      'twitter:title': '🟣 RRHH Cloud para la gestión de su empresa o negocio V-PRO 360🟣',
      'twitter:description': '🟣 RRHH Cloud para la gestión de su empresa o negocio V-PRO 360🟣',
      'og:site_name': 'V-PRO 360',
      'og:title': '🟣 RRHH Cloud para la gestión de su empresa o negocio V-PRO 360🟣',
      'og:description': 'RRHH en la nube,diseñado para optimizar la gestión integral de empresas.Aprovecha la flexibilidad,escalabilidad y seguridad avanzada que ofrece nuestra solución para mejorar tus procesos de negocio,desde finanzas y operaciones hasta recursos humanos y gestión de la cadena de suministro.¡Empieza a potenciar tu negocio!',
      'og:url': 'https://www.vpro360.es/#/recursos-humanos/',
      'og:image': 'https://www.vpro360.es/img/logos/vpro360.png',
      'og:image:width': '600',
      'og:image:height': '315',
      'canonicalUrl': 'https://www.vpro360.es/#/recursos-humanos/',
      'schema.DC': 'https://purl.org/dc/elements/1.1/',
      'schema.DCTERMS': 'https://purl.org/dc/terms/',
      'DC.Description': '🟣 RRHH Cloud para la gestión de su empresa o negocio V-PRO 360🟣',
      'DC.Title': '🟣 RRHH Cloud para la gestión de su empresa o negocio V-PRO 360🟣',
      'DC.Subject': 'RRHH, ERP, SGA, CMS, software de gestion, erp cloud, fichajes, control de fichaje, control de taller, software contable',
      'DC.Identifier': 'https://www.vpro360.es/#/recursos-humanos/',
    },
  },
  {
    path: '/sga-cloud',
    component: Sga,
    name: 'sga-cloud',
  },
  {
    path: '/cms-cloud',
    component: Cms,
    name: 'cms-cloud',
  },
  {
    path: '/login-clientes',
    component: LoginClientes,
    name: 'login-clientes',
  },
  {
    path: '/crear-cuenta-demo',
    component: CrearCuentaDemo,
    name: 'crear-cuenta-demo',
  },
  {
    path: '/erp',
    component: Erp, 
  },
  {
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "NoPageFound"*/ '@/modulos/erp/layouts/404/NoPageFound'
      ),
  },
  {
    path: '/logs',
    name: 'logs',
    component: () =>
      import(/* webpackChunkName: "logs"*/ '@/modulos/logs/Logs'),
  },
  {
    path: '/documentation-api',
    name: 'documentation-api',
    component: () =>
      import(
        /* webpackChunkName: "documentation-api"*/ '@/modulos/documentation/DocumentationApi'
      ),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(/* webpackChunkName: "settings"*/ '@/modulos/settings/Settings'),
  },
  {
    path: '/api/firmar-cita-taller/:id',
    component: () =>
      import(/* webpackChunkName: "settings"*/ '@/modulos/taller/components/FirmaCitaTallerComponent'),
  },
  {
    path: '/api/firmar-vacaciones/:id',
    component: () =>
      import(/* webpackChunkName: "settings"*/ '@/modulos/rrhh/components/vacaciones/FirmaVacacionesComponent'),
  },
  /**************************************************
   * RRHH aplicación móvil                          *             
   ***************************************************/
  {
    path: '/rrhh',
    component: RRHH,
    name: 'rrhh',
  },
  {
    path: '/google-calendar',
    component: GoogleCalendar,
    name: 'google-calendar',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// SEO
router.beforeEach((to, from, next) => {
  updateMetaTags(to.meta);
  next();
});

export default router;

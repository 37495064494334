<template>
  <web-layout>
    <div class="container" style="margin-top: 200px; margin-bottom: 200px">
      <div class="row">
        <div class="col-12 col-md-4 col-xl-3 m-auto">
          <h1 class="text-center h5">Acceso Clientes</h1>
          <div class="card p-4 shadow">
            <div class="alert alert-warning texto13px" v-if="this.errorMessage">
              <span>{{ this.errorMessage }}</span>
            </div>
            <form @submit.prevent="login">
              <div class="col mb-3">
                <label class="form-label fw-bold"
                  >Identificador del cliente</label
                >
                <input
                  v-model="cliente_id"
                  type="text"
                  class="form-control"
                  required
                  autofocus
                />
              </div>

              <div class="col mb-3">
                <label class="form-label fw-bold">Usuario</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-control"
                  required
                  autofocus
                />
              </div>

              <div class="col mb-4">
                <label class="form-label fw-bold">Contraseña del cliente</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  required
                />
              </div>
              <div class="d-grid gap-2">
                <button class="btn boton">ENTRAR</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-violeta modal-container d-flex justify-content-center align-items-center"
      v-if="loading"
    >
      <div class="col-8 bg-violeta p-5">
        <Spinner v-if="loading" />
        <div class="w-100 border rounded" style="height: 33px !important">
          <div
            class="bg-light d-flex justify-content-center rounded align-items-center"
            style="height: 33px !important"
            :style="{ width: (this.loadingStatus / 26) * 100 + '%' }"
          >
            <span v-if="this.loadingStatus > 2" style="color: #553285"
              >{{ ((this.loadingStatus / 26) * 100).toFixed(2) }}%</span
            >
          </div>
        </div>
      </div>
    </div>
  </web-layout>
</template>

<script>
import BASE_URL from '@/config';
import axios from 'axios';
import 'animate.css';
import { swalAlert } from '@/helpers/swalHelper';
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    WebLayout: defineAsyncComponent(() => import('@/web/WebLayout.vue')),
    Spinner: defineAsyncComponent(() =>
      import('@/components/SpinnerCohete.vue'),
    ),
  },

  data() {
    return {
      loading: false,
      loadingStatus: 0,
      cliente_id: '',
      email: '',
      password: '',
      datosUsuario: Object,
      validaciones: false,
      errorMessage: null,
    };
  },

  methods: {
    async login() {
      this.errorMessage = null;
      let formData = new FormData();
      formData.append('cliente_id', this.cliente_id);
      formData.append('email', this.email);
      formData.append('password', this.password);
      this.loading = true;
      await axios
        .post(`${BASE_URL}/api/login-clientes`, formData)
        .then(async (response) => {
          if (response.data.permiso === 'autorizado') {

            // OJO !!!! Comentar en producción. Expone los datos del usuario
            //console.log("login(): ", response)

            this.$store.usuarioAutorizado = true;
            this.datosUsuario = response.data.usuario;

            // Comprobar si el usuario está activo. Si está desactivado no se puede loguear
            if (parseInt(this.datosUsuario.estado) === 0) {
              swalAlert(
                'Usuario desactivado. Contacte con el administrador.',
                this.$swal,
                this.$emit,
                false,
              );
              return;
            }
            this.cambiarEstadoCarga();

            // DATOS COMO CLIENTE
            this.$store.peticionesDesdeRRHH = 0;
            this.$store.idClienteGeneral = parseInt(response.data.cliente.id);
            this.$store.nombreClienteGeneral = response.data.cliente.nombre;
            this.$store.emailClienteGeneral = response.data.cliente.email;
            this.$store.identificadorLargoClienteGeneral = parseInt(
              response.data.cliente.identificador_cliente,
            );
            this.$store.telefonoClienteGeneral = response.data.cliente.telefono;
            this.$store.tipoClienteGeneral = parseInt(
              response.data.cliente.tipo,
            );
            this.$store.fechaAltaClienteGeneral =
              response.data.cliente.updated_at;
            this.$store.nombre_empresa = response.data.cliente.nombre_empresa;
            this.$store.nombre_empresa_corto =
              response.data.cliente.nombre_empresa_corto;
            this.$store.token = response.data.cliente.token;
            this.$store.token_usuario = response.data.cliente.token_usuario;
            this.$store.aparato_fichaje = parseInt(
              response.data.cliente.aparato_fichaje,
            );
            this.cambiarEstadoCarga();
            // Módulos
            this.$store.modulo_erp = parseInt(response.data.cliente.modulo_erp);
            this.$store.modulo_rrhh = parseInt(
              response.data.cliente.modulo_rrhh,
            );
            this.$store.modulo_contabilidad = parseInt(
              response.data.cliente.modulo_contabilidad,
            );
            this.$store.modulo_marketing = parseInt(
              response.data.cliente.modulo_marketing,
            );
            this.$store.modulo_configuracion = parseInt(
              response.data.cliente.modulo_configuracion,
            );
            this.$store.modulo_documentacion = parseInt(
              response.data.cliente.modulo_documentacion,
            );
            this.$store.modulo_logs = parseInt(
              response.data.cliente.modulo_logs,
            );
            this.$store.modulo_personalizacion = parseInt(
              response.data.cliente.modulo_personalizacion,
            );
            this.$store.modulo_soporte = parseInt(
              response.data.cliente.modulo_soporte,
            );
            this.$store.modulo_sga = parseInt(response.data.cliente.modulo_sga);
            this.$store.modulo_crm = parseInt(response.data.cliente.modulo_crm);
            this.$store.modulo_bi = parseInt(response.data.cliente.modulo_bi);
            this.$store.modulo_cms = parseInt(response.data.cliente.modulo_cms);
            this.$store.modulo_web = parseInt(response.data.cliente.modulo_web);
            this.$store.modulo_taller = parseInt(
              response.data.cliente.modulo_taller,
            );
            this.cambiarEstadoCarga();

            // Submódulos
            this.$store.submodulo_fabricantes = parseInt(
              response.data.cliente.submodulo_fabricantes,
            );
            this.$store.submodulo_agentes = parseInt(
              response.data.cliente.submodulo_agentes,
            );
            this.$store.submodulo_grupos_comerciales = parseInt(
              response.data.cliente.submodulo_grupos_comerciales,
            );
            this.$store.submodulo_impuestos = parseInt(
              response.data.cliente.submodulo_impuestos,
            );
            this.cambiarEstadoCarga();

            // Submódulos vacaciones
            this.$store.submodulo_vacaciones_solicitar = parseInt(
              response.data.cliente.submodulo_vacaciones_solicitar,
            );
            this.$store.submodulo_vacaciones_pendientes_aprobar = parseInt(
              response.data.cliente.submodulo_vacaciones_pendientes_aprobar,
            );
            this.$store.submodulo_vacaciones_dias_disponibles = parseInt(
              response.data.cliente.submodulo_vacaciones_dias_disponibles,
            );
            this.$store.submodulo_vacaciones_normativa = parseInt(
              response.data.cliente.submodulo_vacaciones_normativa,
            );
            this.cambiarEstadoCarga();

            // Vacaciones
            this.$store.modelo_vacaciones_empresa = parseInt(
              response.data.cliente.modelo_vacaciones_empresa,
            );
            this.$store.grupo_vacaciones_secundario = parseInt(
              response.data.cliente.grupo_vacaciones_secundario,
            );
            this.$store.vacaciones_dias_naturales = parseInt(
              response.data.cliente.vacaciones_dias_naturales,
            );

            // Taller
            this.$store.horasTrabajoDisponibles = parseInt(
              response.data.cliente.horas_taller_diarias_disponibles,
            );

            this.cambiarEstadoCarga();

            // DATOS COMO USUARIO
            this.$store.idUsuario = parseInt(this.datosUsuario.id);
            this.$store.idGrupo = parseInt(this.datosUsuario.grupo_id);
            this.$store.idDelegacion = parseInt(
              this.datosUsuario.delegacion_id,
            );
            this.$store.colorUsuario = this.datosUsuario.color;
            this.$store.fechaAlta = this.datosUsuario.fecha_alta;
            this.$store.imagen = this.datosUsuario.imagen;
            this.$store.IdDelegacionUsuario = this.datosUsuario.delegacion_id;
            this.$store.zona_festivo_id = parseInt(
              this.datosUsuario.zona_festivo_id,
            );
            this.$store.crosschex_id = parseInt(this.datosUsuario.crosschex_id);
            this.$store.nombreUsuario = this.datosUsuario.name;
            this.$store.primerApellidoUsuario = this.datosUsuario.apellidos;
            this.$store.email = this.datosUsuario.email;
            this.$store.passwordUsuario = response.data.password;
            this.$store.rol = parseInt(this.datosUsuario.rol);
            if (this.$store.aparato_fichaje == 1) {
              this.$store.crosschex_id = parseInt(
                this.datosUsuario.crosschex_id,
              );
            } else {
              this.$store.crosschex_id = null;
            }
            this.cambiarEstadoCarga();
            // Permisos módulos
            this.$store.permiso_modulo_erp = parseInt(
              this.datosUsuario.permiso_modulo_erp,
            );

            this.$store.permiso_modulo_comercial = parseInt(
              this.datosUsuario.permiso_modulo_comercial,
            );
            this.$store.permiso_modulo_contabilidad = parseInt(
              this.datosUsuario.permiso_modulo_contabilidad,
            );
            this.$store.permiso_modulo_marketing = parseInt(
              this.datosUsuario.permiso_modulo_marketing,
            );
            this.$store.permiso_modulo_rrhh = parseInt(
              this.datosUsuario.permiso_modulo_rrhh,
            );
            this.$store.permiso_modulo_soporte = parseInt(
              this.datosUsuario.permiso_modulo_soporte,
            );
            this.$store.permiso_modulo_crm = parseInt(
              this.datosUsuario.permiso_modulo_crm,
            );
            this.$store.permiso_modulo_bi = parseInt(
              this.datosUsuario.permiso_modulo_bi,
            );
            this.$store.permiso_modulo_sga = parseInt(
              this.datosUsuario.permiso_modulo_sga,
            );
            this.$store.permiso_modulo_cms = parseInt(
              this.datosUsuario.permiso_modulo_cms,
            );
            this.$store.permiso_modulo_web = parseInt(
              this.datosUsuario.permiso_modulo_web,
            );
            this.$store.permiso_modulo_taller = parseInt(
              this.datosUsuario.permiso_modulo_taller,
            );
            this.$store.permiso_abono_clientes = parseInt(
              this.datosUsuario.permiso_abono_clientes,
            );
            this.$store.permiso_categorias = parseInt(
              this.datosUsuario.permiso_categorias,
            );
            this.$store.permiso_clientes = parseInt(
              this.datosUsuario.permiso_clientes,
            );
            this.$store.permiso_exportar_informes = parseInt(
              this.datosUsuario.permiso_exportar_informes,
            );
            this.$store.permiso_modificar_lineas_factura = parseInt(
              this.datosUsuario.permiso_modificar_lineas_factura,
            );

            this.$store.permiso_recepcion_pedidos = parseInt(
              this.datosUsuario.permiso_recepcion_pedidos,
            );
            this.$store.permiso_referencias =
              this.datosUsuario.permiso_referencias;
            this.$store.permiso_subcategorias = parseInt(
              this.datosUsuario.permiso_subcategorias,
            );
            this.$store.permiso_ver_todos_almacenes = parseInt(
              this.datosUsuario.permiso_ver_todos_almacenes,
            );
            this.$store.permiso_cambiar_forma_pago = parseInt(
              this.datosUsuario.permiso_cambiar_forma_pago,
            );
            this.$store.permiso_cambiar_importe_descuento = parseInt(
              this.datosUsuario.permiso_cambiar_importe_descuento,
            );
            this.$store.permiso_cambiar_usuario = parseInt(
              this.datosUsuario.permiso_cambiar_usuario,
            );
            this.cambiarEstadoCarga();

            // Obtener datos de calendarios, vacaciones, ...
            await this.getLoginData();

            // Navegar a la página del login de usuarios
            this.$router.push('/erp');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (parseInt(error.response.status) === 429) {
              swalAlert(
                'Servidor en mantenimiento. Inténtelo de nuevo más tarde.',
                this.$swal,
                this.$emit,
                false,
              );
            }
            if (
              error.response.data.message !==
              'Attempt to read property "password" on null'
            ) {
              this.errorMessage = error.response.data.message;
            } else {
              this.errorMessage = 'Usuario no encontrado';
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getLoginData() {
      const params = {
        token: this.$store.token,
        token_usuario: this.$store.token_usuario,
        demo_id: this.$store.idClienteGeneral,
      };
      await axios
        .get(`${BASE_URL}/api/datos-iniciales`, { params })
        .then(async (response) => {
          //console.log('Datos iniciales: ', response.data);

          this.$store.usuariosArray = response.data.usuarios;
          this.cambiarEstadoCarga();

          this.$store.delegacionesArray = response.data.delegaciones;
          this.$store.gruposArray = response.data.grupos;
          this.cambiarEstadoCarga();

          this.$store.empresasArray = response.data.empresas;
          this.$store.empresasArray.forEach((empresa) => {
            empresa.label = empresa.denominacion;
          });
          this.cambiarEstadoCarga();

          this.$store.zonasFestivosArray = response.data.zonasFestivos;
          this.$store.zonasFestivosArray.forEach((zona) => {
            zona.dias = JSON.parse(zona.dias);
          });
          this.cambiarEstadoCarga();
        })
        .catch((error) => {
          if (error.response) {
            if (parseInt(error.response.status) === 429) {
              swalAlert(
                'Servidor en mantenimiento. Inténtelo de nuevo más tarde.',
                this.$swal,
                this.$emit,
                false,
              );
            }
          }
          console.error('Error al obtener los datos en el Login:', error);
        })
        .finally(() => {
          return;
        });
    },

    async cambiarEstadoCarga() {
      this.loadingStatus++;
    },
  },
  mounted() {
    this.$store.usuarioAutorizado = false; // Para que siempre que se cargue el login sea falso
    this.$store.fichaje = 0;
    //console.log('Usuario autorizado en login: ', this.$store.usuarioAutorizado);
    this.paso1 = true;
  },
};
</script>
<style scoped>
.modal-container {
  z-index: 5000;
}
</style>

<template>
  <div class="login-container">
    <!-- Video de fondo -->
    <video autoplay muted loop id="bg-video">
      <source src="@/assets/videos/programacion.mp4" type="video/mp4" />
      Tu navegador no soporta el video.
    </video>

    <!-- Formulario de Login con efecto 3D -->
    <div class="login-form">
      <img src="@/assets/imagenes/logos/vpro360-2.png" alt="Logo VPRO360" />
      <h1>Iniciar Sesión</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">Identificador del cliente:</label>
          <input
            type="text"
            v-model="cliente_id"
            class="form-control"
            placeholder="Ingresa tu identificador de cliente"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">Usuario:</label>
          <input
            type="email"
            v-model="email"
            class="form-control"
            placeholder="Ingresa tu usuario"
            required
          />
        </div>
        <div class="form-group">
          <label for="password">Contraseña:</label>
          <input
            type="password"
            v-model="password"
            class="form-control"
            placeholder="Ingresa tu contraseña"
            required
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block">
          Iniciar Sesión
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import BASE_URL from '@/config';
import axios from 'axios';
import 'animate.css';
import { swalAlert } from '@/helpers/swalHelper';
import { defineAsyncComponent } from 'vue';

export default {
  data() {
    return {
      cliente_id: '',
      email: '',
      password: '',
      datosUsuario: Object,
      validaciones: false,
      errorMessage: null,
    };
  },
  methods: {
    async login() {
      console.log('login()');

      let formData = new FormData();
      formData.append('cliente_id', this.cliente_id);
      formData.append('email', this.email);
      formData.append('password', this.password);

      await axios
        .post(`${BASE_URL}/api/login-clientes`, formData)
        .then(async (response) => {
          if (response.data.permiso === 'autorizado') {
            this.$store.usuarioAutorizado = true;
            this.datosUsuario = response.data.usuario;

            // Comprobar si el usuario está activo. Si está desactivado no se puede loguear
            if (parseInt(this.datosUsuario.estado) === 0) {
              swalAlert(
                'Usuario desactivado. Contacte con el administrador.',
                this.$swal,
                this.$emit,
                false,
              );
              return;
            }

            // DATOS COMO CLIENTE
            this.$store.peticionesDesdeRRHH = 0;
            this.$store.idClienteGeneral = parseInt(response.data.cliente.id);
            this.$store.nombreClienteGeneral = response.data.cliente.nombre;
            this.$store.emailClienteGeneral = response.data.cliente.email;
            this.$store.identificadorLargoClienteGeneral = parseInt(
              response.data.cliente.identificador_cliente,
            );
            this.$store.telefonoClienteGeneral = response.data.cliente.telefono;
            this.$store.tipoClienteGeneral = parseInt(
              response.data.cliente.tipo,
            );
            this.$store.fechaAltaClienteGeneral =
              response.data.cliente.updated_at;
            this.$store.nombre_empresa = response.data.cliente.nombre_empresa;
            this.$store.nombre_empresa_corto =
              response.data.cliente.nombre_empresa_corto;
            this.$store.token = response.data.cliente.token;
            this.$store.token_usuario = response.data.cliente.token_usuario;
            this.$store.aparato_fichaje = parseInt(
              response.data.cliente.aparato_fichaje,
            );

            // Módulos
            this.$store.modulo_erp = parseInt(response.data.cliente.modulo_erp);
            this.$store.modulo_rrhh = parseInt(
              response.data.cliente.modulo_rrhh,
            );
            this.$store.modulo_contabilidad = parseInt(
              response.data.cliente.modulo_contabilidad,
            );
            this.$store.modulo_marketing = parseInt(
              response.data.cliente.modulo_marketing,
            );
            this.$store.modulo_configuracion = parseInt(
              response.data.cliente.modulo_configuracion,
            );
            this.$store.modulo_documentacion = parseInt(
              response.data.cliente.modulo_documentacion,
            );
            this.$store.modulo_logs = parseInt(
              response.data.cliente.modulo_logs,
            );
            this.$store.modulo_personalizacion = parseInt(
              response.data.cliente.modulo_personalizacion,
            );
            this.$store.modulo_soporte = parseInt(
              response.data.cliente.modulo_soporte,
            );
            this.$store.modulo_sga = parseInt(response.data.cliente.modulo_sga);
            this.$store.modulo_crm = parseInt(response.data.cliente.modulo_crm);
            this.$store.modulo_bi = parseInt(response.data.cliente.modulo_bi);
            this.$store.modulo_cms = parseInt(response.data.cliente.modulo_cms);
            this.$store.modulo_web = parseInt(response.data.cliente.modulo_web);
            this.$store.modulo_taller = parseInt(
              response.data.cliente.modulo_taller,
            );

            // Submódulos
            this.$store.submodulo_fabricantes = parseInt(
              response.data.cliente.submodulo_fabricantes,
            );
            this.$store.submodulo_agentes = parseInt(
              response.data.cliente.submodulo_agentes,
            );
            this.$store.submodulo_grupos_comerciales = parseInt(
              response.data.cliente.submodulo_grupos_comerciales,
            );
            this.$store.submodulo_impuestos = parseInt(
              response.data.cliente.submodulo_impuestos,
            );

            // Submódulos vacaciones
            this.$store.submodulo_vacaciones_solicitar = parseInt(
              response.data.cliente.submodulo_vacaciones_solicitar,
            );
            this.$store.submodulo_vacaciones_pendientes_aprobar = parseInt(
              response.data.cliente.submodulo_vacaciones_pendientes_aprobar,
            );
            this.$store.submodulo_vacaciones_dias_disponibles = parseInt(
              response.data.cliente.submodulo_vacaciones_dias_disponibles,
            );
            this.$store.submodulo_vacaciones_normativa = parseInt(
              response.data.cliente.submodulo_vacaciones_normativa,
            );

            // Vacaciones
            this.$store.modelo_vacaciones_empresa = parseInt(
              response.data.cliente.modelo_vacaciones_empresa,
            );
            this.$store.grupo_vacaciones_secundario = parseInt(
              response.data.cliente.grupo_vacaciones_secundario,
            );
            this.$store.vacaciones_dias_naturales = parseInt(
              response.data.cliente.vacaciones_dias_naturales,
            );

            // Taller
            this.$store.horasTrabajoDisponibles = parseInt(
              response.data.cliente.horas_taller_diarias_disponibles,
            );

            // DATOS COMO USUARIO
            this.$store.idUsuario = parseInt(this.datosUsuario.id);
            this.$store.idGrupo = parseInt(this.datosUsuario.grupo_id);
            this.$store.idDelegacion = parseInt(
              this.datosUsuario.delegacion_id,
            );
            this.$store.colorUsuario = this.datosUsuario.color;
            this.$store.fechaAlta = this.datosUsuario.fecha_alta;
            this.$store.imagen = this.datosUsuario.imagen;
            this.$store.IdDelegacionUsuario = this.datosUsuario.delegacion_id;
            this.$store.zona_festivo_id = parseInt(
              this.datosUsuario.zona_festivo_id,
            );
            this.$store.crosschex_id = parseInt(this.datosUsuario.crosschex_id);
            this.$store.nombreUsuario = this.datosUsuario.name;
            this.$store.primerApellidoUsuario = this.datosUsuario.apellidos;
            this.$store.email = this.datosUsuario.email;
            this.$store.passwordUsuario = response.data.password;
            this.$store.rol = parseInt(this.datosUsuario.rol);
            if (this.$store.aparato_fichaje == 1) {
              this.$store.crosschex_id = parseInt(
                this.datosUsuario.crosschex_id,
              );
            } else {
              this.$store.crosschex_id = null;
            }

            // Permisos módulos
            this.$store.permiso_modulo_erp = parseInt(
              this.datosUsuario.permiso_modulo_erp,
            );

            this.$store.permiso_modulo_comercial = parseInt(
              this.datosUsuario.permiso_modulo_comercial,
            );
            this.$store.permiso_modulo_contabilidad = parseInt(
              this.datosUsuario.permiso_modulo_contabilidad,
            );
            this.$store.permiso_modulo_marketing = parseInt(
              this.datosUsuario.permiso_modulo_marketing,
            );
            this.$store.permiso_modulo_rrhh = parseInt(
              this.datosUsuario.permiso_modulo_rrhh,
            );
            this.$store.permiso_modulo_soporte = parseInt(
              this.datosUsuario.permiso_modulo_soporte,
            );
            this.$store.permiso_modulo_crm = parseInt(
              this.datosUsuario.permiso_modulo_crm,
            );
            this.$store.permiso_modulo_bi = parseInt(
              this.datosUsuario.permiso_modulo_bi,
            );
            this.$store.permiso_modulo_sga = parseInt(
              this.datosUsuario.permiso_modulo_sga,
            );
            this.$store.permiso_modulo_cms = parseInt(
              this.datosUsuario.permiso_modulo_cms,
            );
            this.$store.permiso_modulo_web = parseInt(
              this.datosUsuario.permiso_modulo_web,
            );
            this.$store.permiso_modulo_taller = parseInt(
              this.datosUsuario.permiso_modulo_taller,
            );
            this.$store.permiso_abono_clientes = parseInt(
              this.datosUsuario.permiso_abono_clientes,
            );
            this.$store.permiso_categorias = parseInt(
              this.datosUsuario.permiso_categorias,
            );
            this.$store.permiso_clientes = parseInt(
              this.datosUsuario.permiso_clientes,
            );
            this.$store.permiso_exportar_informes = parseInt(
              this.datosUsuario.permiso_exportar_informes,
            );
            this.$store.permiso_modificar_lineas_factura = parseInt(
              this.datosUsuario.permiso_modificar_lineas_factura,
            );

            this.$store.permiso_recepcion_pedidos = parseInt(
              this.datosUsuario.permiso_recepcion_pedidos,
            );
            this.$store.permiso_referencias =
              this.datosUsuario.permiso_referencias;
            this.$store.permiso_subcategorias = parseInt(
              this.datosUsuario.permiso_subcategorias,
            );
            this.$store.permiso_ver_todos_almacenes = parseInt(
              this.datosUsuario.permiso_ver_todos_almacenes,
            );
            this.$store.permiso_cambiar_forma_pago = parseInt(
              this.datosUsuario.permiso_cambiar_forma_pago,
            );
            this.$store.permiso_cambiar_importe_descuento = parseInt(
              this.datosUsuario.permiso_cambiar_importe_descuento,
            );
            this.$store.permiso_cambiar_usuario = parseInt(
              this.datosUsuario.permiso_cambiar_usuario,
            );

            await this.getLoginData();
            // Navegar a la página del login de usuarios
            this.$router.push('/erp');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (parseInt(error.response.status) === 429) {
              swalAlert(
                'Servidor en mantenimiento. Inténtelo de nuevo más tarde.',
                this.$swal,
                this.$emit,
                false,
              );
            }
            if (
              error.response.data.message !==
              'Attempt to read property "password" on null'
            ) {
              this.errorMessage = error.response.data.message;
            } else {
              this.errorMessage = 'Usuario no encontrado';
            }
          }
        });
    },

    async getLoginData() {
      console.log('getLoginData()');
      const params = {
        token: this.$store.token,
        token_usuario: this.$store.token_usuario,
        demo_id: this.$store.idClienteGeneral,
      };
      await axios
        .get(`${BASE_URL}/api/datos-iniciales`, { params })
        .then(async (response) => {
          //console.log('Datos iniciales: ', response.data);

          this.$store.usuariosArray = response.data.usuarios;

          this.$store.delegacionesArray = response.data.delegaciones;
          this.$store.gruposArray = response.data.grupos;

          this.$store.empresasArray = response.data.empresas;
          this.$store.empresasArray.forEach((empresa) => {
            empresa.label = empresa.denominacion;
          });

          this.$store.zonasFestivosArray = response.data.zonasFestivos;
          this.$store.zonasFestivosArray.forEach((zona) => {
            zona.dias = JSON.parse(zona.dias);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (parseInt(error.response.status) === 429) {
              swalAlert(
                'Servidor en mantenimiento. Inténtelo de nuevo más tarde.',
                this.$swal,
                this.$emit,
                false,
              );
            }
          }
          console.error('Error al obtener los datos en el Login:', error);
        })
        .finally(() => {
          return;
        });
    },
  },

  mounted() {
    this.$store.usuarioAutorizado = false; // Para que siempre que se cargue el login sea falso
    this.$store.fichaje = 0;
    //console.log('Usuario autorizado en login: ', this.$store.usuarioAutorizado);
  },
};
</script>

<style scoped>
/* Video de fondo ocupa toda la pantalla */
#bg-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

/* Contenedor principal centrado */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1;
}

/* Formulario de login con efecto 3D */
.login-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5),
    /* Sombra principal */ 0 20px 50px rgba(0, 0, 0, 0.3); /* Sombra secundaria para mayor profundidad */
  width: 100%;
  max-width: 400px;
  text-align: center;
  transform: perspective(1000px) rotateX(10deg); /* Efecto de rotación 3D */
  transition: transform 0.5s ease; /* Transición suave al hacer hover */
}

/* Efecto hover para ampliar el efecto 3D */
.login-form:hover {
  transform: perspective(1000px) rotateX(0deg) translateY(-10px); /* Levanta el formulario en hover */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.6), 0 30px 60px rgba(0, 0, 0, 0.4);
}

/* Estilo del logo */
.login-form img {
  width: 150px;
  margin-bottom: 20px;
}

/* Estilos del formulario */
.form-group {
  margin-bottom: 20px;
}

.btn-block {
  width: 100%;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* Sombra de texto para mayor profundidad */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .login-form {
    padding: 20px;
    max-width: 90%;
  }

  h1 {
    font-size: 20px;
  }

  .login-form img {
    width: 120px;
  }
}
</style>

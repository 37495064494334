<template>
  <web-layout>
    <div class="contenedor">
      <div class="row">
        <div
          class="col-12 col-sm-10 col-md-7 col-lg-6 col-xl-5 col-xxl-4 m-auto"
        >
          <h1 class="text-center texto-violeta">Prueba gratis</h1>
          <h6 class="text-center">Sin datos de tarjeta ni cuenta bancaria.</h6>

          <!-- Paso 1-->
          <div class="card p-4 shadow">
            <div class="row d-flex flex-row justify-content-evenly">
              <button class="btn btn-sm col-md-3 col-3 rounded p-2 text-light mb-3 text-center" :class="this.pasoActual == 1 ? 'bg-violeta' : 'boton-outline-violeta'" @click="this.pasoActual = 1">Paso 1</button>
              <button class="btn btn-sm col-md-3 col-3 rounded p-2 text-light mb-3 text-center" :class="this.pasoActual == 2 ? 'bg-violeta' : 'boton-outline-violeta'" @click="this.pasoActual = 2">Paso 2</button>
              <button class="btn btn-sm col-md-3 col-3 rounded p-2 text-light mb-3 text-center" :class="this.pasoActual == 3 ? 'bg-violeta' : 'boton-outline-violeta'" @click="this.pasoActual = 3">Paso 3</button>
            </div>
            <div class="w-100 col-100" v-if="this.pasoActual == 1">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label">Nombre Empresa</label>
                    <input
                      v-model="nombreEmpresa"
                      type="text"
                      class="form-control"
                      autofocus
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label">Cargo</label>
                    <select v-model="cargo" class="form-control" >
                      <option v-for="cargo in this.cargosSelect" :value="cargo">
                        {{ cargo }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label">Nombre</label>
                    <input v-model="nombre" type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label class="form-label">Apellidos</label>
                    <input v-model="apellidos" type="text" class="form-control" />
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <label class="form-label">Email (Correo empresarial)</label>
                <input v-model="email" type="email" class="form-control" />
              </div>

              <div class="form-group mb-3">
                <label class="form-label">Contraseña</label>
                <input v-model="password" type="password" class="form-control" />
              </div>

              <div class="form-group mb-3">
                <label class="form-label">Teléfono</label>
                <input v-model="telefono" type="text" class="form-control" />
              </div>

              <div class="form-group mb-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    checked
                  />
                </div>
                <label class="form-check-label" for="flexCheckChecked">
                  Al continuar declaras que aceptas las Condiciones Generales y la
                  Política de privacidad.
                </label>
              </div>
            </div>
            <div class="w-100 col-100" v-if="this.pasoActual == 2">
              <div class="form-group mb-3">
                <label class="form-label">Número de empleados</label>
                <select value="numero_empleados" class="form-control">
                  <option value="1-5">De 1 a 5 empleados</option>
                  <option value="6-10">De 6 a 10 empleados</option>
                  <option value="11-20">De 11 a 20 empleados</option>
                  <option value="21-50">De 21 a 50 empleados</option>
                  <option value="51-100">De 51 a 100 empleados</option>
                  <option value="100-10000">Más de 100 empleados</option>
                </select>
              </div>
            </div>
            <div class="w-100 col-100" v-if="this.pasoActual == 3">
              <div class="form-group mb-3">
                <label class="form-label">¿Días de vacaciones anuales?</label>
                <select value="vacaciones_dias_naturales" class="form-control">
                  <option value="0">22 días laborales</option>
                  <option value="1">30 días naturales</option>
                </select>
              </div>
              <div class="form-group mb-3">
                <label class="form-label"
                  >¿Desea que los trabajadores puedan solicitar las vacaciones a
                  través de la aplicación?</label
                >
                <select value="vacaciones_dias_naturales" class="form-control">
                  <option value="1">Sí</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <button v-if="this.pasoActual < 3"
              @click="this.pasoActual++"
              class="btn boton-outline-violeta text-uppercase mb-3"
            >
              Siguiente
            </button>

            <button v-if="this.pasoActual == 3"
              @click="crearCuentaDemo"
              class="btn boton-outline-violeta text-uppercase mb-3"
            >
              CREAR DEMO
            </button>
            <div class="form-group mb-3">
              <router-link :to="{ name: 'login-clientes' }" class="text-sky-700"
                >¿Ya tienes una cuenta? Inicia Sesión</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </web-layout>
</template>

<script>
import BASE_URL from '@/config';
import axios from 'axios';
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    WebLayout: defineAsyncComponent(() => import('@/web/WebLayout.vue')),
    Navbar: defineAsyncComponent(() => import('@/web/components/Navbar.vue')),
    Footer: defineAsyncComponent(() => import('@/web/components/Footer.vue')),
  },

  data() {
    return {
      nombreEmpresa: '',
      nombre: '',
      apellidos: '',
      cargo: '',
      email: '',
      password: '',
      telefono: '',
      remember: false,
      numero_empleados: 0,
      vacaciones_dias_naturales: 0,
      submodulo_vacaciones_solicitar: 0,
      pasoActual:1,
      cargosSelect: ["CEO", "Administración","Empleado", "Recursos Humanos",  "Ventas"],
    };
  },

  methods: {
    async crearCuentaDemo() {
      const formData = new FormData();
      formData.append('nombre_empresa', this.nombreEmpresa);
      formData.append('nombre', this.nombre);
      formData.append('apellidos', this.apellidos);
      formData.append('cargo', this.cargo);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('telefono', this.telefono);
      formData.append('numero_empleados', this.numero_empleados);
      formData.append(
        'vacaciones_dias_naturales',
        this.vacaciones_dias_naturales,
      );
      formData.append(
        'submodulo_vacaciones_solicitar',
        this.submodulo_vacaciones_solicitar,
      );

      await axios
        .post(`${BASE_URL}/api/crear-cuenta-demo`, formData)
        .then((response) => {
          console.log(response);

          if (parseInt(response.status) === 201) {
            alert(
              'Cuenta creada. Revise su correo para ver los datos de acceso.',
            );
            this.$router.push({ name: 'login-clientes' });
          } else {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.contenedor {
  margin-top: 120px;
  margin-bottom: 120px;
}

a {
  text-decoration: none;
}
</style>
